import { createContext, useEffect, useState } from "react";
import {
  login,
  logout,
  updateLoginUserProfile,
  updateLoggedInUserProfile,
  signup,
  resendEmailVerification,
  verifyEmail,
  changeUserPassword,
  forgotUserPassword,
  resetUserPassword,
  supportServiceMail,
  createUserShortcut,
  checkUserExists,
  checkUserEmailVerificationStatus,
} from "Services/userService";
import * as LocalStorageService from "../utils/localStorageService";
import {
  shopifyUpdateDiscount,
  updateShopifyShopDetail,
  updateThreshold,
  updateUserShopping
} from "Services/externalApiService";
import { uploadFile } from "Services/uploadService";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  const [user, setUser] = useState(() =>
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null
  );
  const [loggedIn, setLoggedIn] = useState(() =>
    localStorage.getItem("authTokens") ? true : false
  );
  const [userProfile, setUserProfile] = useState(() =>
    localStorage.getItem("userProfile")
      ? JSON.parse(localStorage.getItem("userProfile"))
      : null
  );

  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [emailVerificationLoading, setEmailVerificationLoading] = useState(false);

  //errors
  const [loginErrors, setLoginErrors] = useState(null);
  const [signupErrors, setSignupErrors] = useState(null);
  const [error, setError] = useState(null);

  let loginUser = async (e) => {
    let body = {
      email: e.email,
      password: e.password,
    };

    try {
      const response = await login(body);
      let userData = {
        firstName: response.data.data.first_name,
        lastName: response.data.data.last_name,
        email: response.data.data.email,
        uid: response.data.data.uid,
        role: response.data.data.role,
        isVerified: response.data.data.is_verified,
        hasPasswordExpired: response.data.data.has_password_expired,
        isFirstTime: response.data.data.is_soft_host,
        completed_onboarding: response.data.data.completed_onboarding,
        payment_method: response.data.data.payment_method,
      };
      let tokens = {
        access: response.data.data.access,
        refresh: response.data.data.refresh,
      };
      setAuthTokens(tokens);
      setLoggedIn(true);
      setUser(userData);
      localStorage.setItem("authTokens", JSON.stringify(tokens));
      localStorage.setItem("user", JSON.stringify(userData));
      return response;
    } catch (error) {
      setLoginErrors(error.response.data);
      throw error;
    }
  };

  let signUpUser = async (data) => {
    try {
      const response = await signup(data);
      return response;
    } catch (error) {
      if (error.response.data.email) {
        setSignupErrors(error?.response?.data?.email[0]);
      } else if (error.response.data.confirm_password) {
        setSignupErrors(error?.response?.data?.confirm_password[0]);
      } else if (error.response.data.password) {
        setSignupErrors(error?.response?.data?.password[0]);
      } else if (error.response.data.shopify) {
        setSignupErrors(error?.response?.data?.shopify[0]);
      } else if (error.response.data.role) {
        setSignupErrors(error?.response?.data?.role[0]);
      } else {
        setSignupErrors("Error signing up!");
      }
      throw error;
    }
  };

  let shopifyThreshold = async (data) => {
    try {
      const response = await updateThreshold(data);
      return response;
    } catch (error) {
      throw error;
    }
  };
  let logoutUser = async (e) => {
    let body = {
      refresh: LocalStorageService.getRefreshToken(),
    };
    try {
      const response = await logout(body);
      if (response.status === 200) {
        setAuthTokens(null);
        setUser(null);
        setLoggedIn(false);
        localStorage.clear();
        return response;
      }
    } catch (error) {
      // alert(error.response.data.message);
      throw error;
    }
  };

  let updateProfile = async (e) => {
    try {
      const response = await updateLoggedInUserProfile();
      if (response?.data?.data[0]) {

        if (response?.data?.data.length > 0 && response?.data?.data[0].is_verified) {
          setIsEmailVerified(true);
        } else if (response?.data?.data.length > 0 && !response?.data?.data[0].is_verified) {
          setIsEmailVerified(false);
        }

        setUserProfile(response.data.data[0]);
        localStorage.setItem(
          "userProfile",
          JSON.stringify(response.data.data[0])
        );
        return response;
      }
    } catch (error) {
      throw error;
    }
  };

  let getProfileUser = async () => {
    try {
      const response = await updateLoggedInUserProfile();
      return response;
    } catch (error) {
      throw error;
    }
  };

  let updateUserProfile = async (data) => {
    var body = {
      phone_number: data.phone_number,
      about_me: data.about_me,
      social: {
        instagram_handle: data.instagram_handle,
        twitter_handle: data.twitter_handle,
        tiktok_handle: data.tiktok_handle,
        facebook_handle: data.facebook_handle,
      },
      location: {
        latitude: data.latitude,
        longitude: data.longitude,
        address: data.address,
        street_number: data.street_number,
        city: data.city,
        state: data.state,
        zip_code: data.zip_code,
      },
      profile_picture_id: data.profile_picture_id,
      accounts: data.accounts,
    };
    try {
      const response = await updateLoginUserProfile(body, user.uid);
      if (response.status === 200) {
        updateProfile();
      }
      //should manage to redirect to /user/showroom
      return response;
    } catch (error) {
      console.log("Error on profile form");
      // throw error;
    }
  };

  let updateMultipleEmails = async (data) => {
    try {
      const res = await updateLoginUserProfile(data, user.uid);
      if (res.status === 200) {
        updateProfile();
      }
      return res;
    } catch (error) {
      throw error;
    }
  };

  let updateStoreUserProfile = async (data) => {
    var body = {
      phone_number: data.phone_number,
      about_me: data.about_me,
      social: {
        instagram_handle: data.instagram_handle,
        twitter_handle: data.twitter_handle,
        tiktok_handle: data.tiktok_handle,
        facebook_handle: data.facebook_handle,
      },
      location: {
        latitude: data.latitude,
        longitude: data.longitude,
        address: data.address,
        street_number: data.street_number,
        city: data.city,
        state: data.state,
        zip_code: data.zip_code,
      },
      profile_picture_id: data.profile_picture_id,
      accounts: [
        {
          store_email: data.store_email,
        },
      ],
      store: {
        demo_commission: data.demoCommission,
        demo_commission_type: data.commissionType,
        demo_sales_commission: data.salesCommission,
        demo_sales_commission_type: data.salesCommissionType,
        discount_type: data.discountType,
        discount_given: data.discountGiven,
        payment_frequency: data.paymentFrequency,
        no_show_demo_commission_type: data.no_show_demo_commission_type,
        no_show_demo_commission: data.no_show_demo_commission,
        sales_commission_order_threshold: data?.timeEligibility
      },
    };
    try {
      const response = await updateLoginUserProfile(body, user.uid);
      if (response.status === 200) {
        const dataShopify = {
          storeid: userProfile?.store?.store_id,
          discount_type: data.discountType,
          discount_given: parseFloat(data.discountGiven),
        };
        try {
          const res = await shopifyUpdateDiscount(dataShopify);
          if (res) {
            if (data?.files) {
              const bodyShopify = {
                logo: data?.files,
                videoid: data?.videofile,
                days: data?.shipDate,
                nameShop: data?.shopName,
                storeId: userProfile?.store?.store_id,
              };
              try {
                await updateShopifyShopDetail(bodyShopify);
              } catch (error) {
                console.log("Error Occured");
              }
            }
          }
        } catch (error) {
          console.log("Error Occured");
        }

        updateProfile();
      }
      //should manage to redirect to /user/showroom
      return response;
    } catch (error) {
      console.log("Error on profile form");
      throw error;
    }
  };

  let updateShopifyStoreDetail = async (data) => {
    try {
      const bodyShopify = {
        logo: data.logo,
        days: data.delivery,
        nameShop: data.name,
        storeId: userProfile.store.store_id,
        videoid: data.videoid,
      };
      try {
        const res = await updateShopifyShopDetail(bodyShopify);
        return res;
      } catch (error) {
        console.log("Error Occured");
      }
    } catch (error) {
      console.log("Error Occured");
    }
  };

  //here this will help to upload shopify store logo
  let uploader = async (values) => {
    let body = {
      tag: "Store_logo",
      type: values.files[0].type,
      extension: "jpeg",
      file: values.files[0],
    };
    try {
      const response = await uploadFile(body);
      values.logo = response.data.data.id;
      if (response.status === 200) {
        const updateShopifyStoreLogo = await updateShopifyStoreDetail(values);
        return updateShopifyStoreLogo;
      } else {
        return;
      }
    } catch (error) {
      throw error;
    }
  };

  //here this will help to upload shopify store video
  let storeVideoUploader = async (values) => {
    let body = {
      tag: "Store_video",
      type: values.video.type,
      extension: "mp4",
      file: values.video,
    };
    try {
      const response = await uploadFile(body);
      let payload = {
        storeId: values?.storeid,
        logo: values?.logo,
        nameShop: values?.nameShop,
        days: values?.days,
        videoid: response?.data?.data?.id,
      };
      if (response.status === 200) {
        await updateShopifyShopDetail(payload);
        return response;
      } else {
        return;
      }
    } catch (error) {
      // throw error;
      console.log("Error Occured");
    }
  };

  //Password related stuffs
  let resendVerification = async () => {
    try {
      const response = await resendEmailVerification();
      return response;
    } catch (error) {
      throw error;
    }
  };

  let verificationEmail = async (data, params) => {
    try {
      const response = await verifyEmail(data, params);
      return response;
    } catch (error) {
      throw error;
    }
  };
  let changePasswords = async (value) => {
    const body = {
      old_password: value.old_password,
      password: value.password,
      confirm_password: value.confirm_password,
      type: value.type,
    };
    try {
      const response = await changeUserPassword(body);
      return response;
    } catch (error) {
      setError(error.response.data);
      throw error;
    }
  };
  let forgotPassword = async (value) => {
    const body = {
      email: value.email,
    };
    try {
      const response = await forgotUserPassword(body);
      return response;
    } catch (error) {
      throw error;
    }
  };
  let resetPassword = async (value) => {
    const userId = value.uid;
    const userToken = value.userToken;
    const body = {
      new_password: value.password,
      confirm_password: value.confirm_password,
    };
    try {
      const response = await resetUserPassword(body, userId, userToken);
      return response;
    } catch (error) {
      throw error;
    }
  };
  let sendSupportMail = async (body) => {
    try {
      const res = await supportServiceMail(body);
      return res;
    } catch (error) {
      throw error;
    }
  };

  let updateShopifySignup = async (params) => {
    try {
      await updateUserShopping(params);
    } catch (error) {
      throw error;
    }
  };

  let userCreateShort = async (body, params) => {
    try {
      let res = await createUserShortcut(body, params);
      return res;
    } catch (error) {
      throw error;
    }
  };
  let userExistCheck = async (body) => {
    try {
      let res = await checkUserExists(body);
      return res;
    } catch (error) {
      throw error;
    }
  };
  // useEffect(() => {
  //   if (loggedIn) {
  //     updateProfile();
  //   }
  // }, [loggedIn]);

  /**
   * This function is used to make a api call that checks the email verification of the loggedin host.
   *
   * @returns {void}
   */

  const getEmailVerificationStatus = async () => {

    if (user?.role === 3 || user?.role === 2) {
      if (loggedIn) {
        try {
          setEmailVerificationLoading(true);
          const response = await checkUserEmailVerificationStatus();
          if (response?.data?.data?.is_verified) {
            setIsEmailVerified(true);
          } else {
            setIsEmailVerified(false);
          }
          setEmailVerificationLoading(false);
        } catch (error) {
          setIsEmailVerified(false);
        }
      }
    }
  }

  useEffect(()=> {
    getEmailVerificationStatus()
  },[])

  let contextData = {
    user: user,
    setUser: setUser,
    authTokens: authTokens,
    setAuthTokens: setAuthTokens,
    userProfile: userProfile,
    loginUser: loginUser,
    updateProfile: updateProfile,
    updateUserProfile: updateUserProfile,
    getProfileUser: getProfileUser,
    logoutUser: logoutUser,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    changePasswords: changePasswords,
    resendVerification: resendVerification,
    loginErrors: loginErrors,
    loggedIn: loggedIn,
    setLoggedIn: setLoggedIn,
    signUpUser: signUpUser,
    signupErrors: signupErrors,
    setSignupErrors: setSignupErrors,
    shopifyThreshold: shopifyThreshold,
    verificationEmail: verificationEmail,
    error: error,
    sendSupportMail: sendSupportMail,
    updateStoreUserProfile: updateStoreUserProfile,
    updateMultipleEmails: updateMultipleEmails,
    updateShopifySignup: updateShopifySignup,
    userCreateShort: userCreateShort,
    userExistCheck: userExistCheck,
    updateShopifyStoreDetail: updateShopifyStoreDetail,
    // putToS3: putToS3,
    uploader: uploader,
    storeVideoUploader: storeVideoUploader,

    isEmailVerified,
    emailVerificationLoading,
    setIsEmailVerified
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

import Dashelement from "components/Dashboard/Dashelement";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import AuthContext from "context/AuthContext";
import ProfileContext from "context/ProfileContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import IncompleteProfile from "Pages/dashboard/Profile/IncompleteProfile";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button,Spinner,Table } from "react-bootstrap";
import PayoutAccepted from "./PayoutAccepted";
import "./payout.css";
import PaymentContext from "context/PaymentContext";
import { TEST_IDS } from "../Meetings/testIds";
import { filter } from 'assets/images';
import PayoutFilterModal from "components/Modal/PayoutFIlterModal";
import DemoContext from "context/DemoContext";
import { DATE_FORMATS, SORTING_STATUS } from "utils/constants";
import PayoutFilterbar from "components/Filters/PayoutFilterBar/PayoutFilterbar";
import InfiniteScroll from "react-infinite-scroll-component";
import {message} from "antd"
import { GENERIC_ERROR_MESSAGE } from "utils/constants";
import {Tooltip} from "antd";
import { CaretDown, CaretUp } from 'react-bootstrap-icons';
const Payouts = () => {

  const { userProfile, isEmailVerified, emailVerificationLoading } = useContext(AuthContext);
  let { updatePayoutsWithPagination, updatedPayoutListWithPagination,updatePayouts, nextStephost, prevStephost } =
    useContext(ProfileContext);
    const {fetchAllProducts,fetchAllStores} = useContext(DemoContext);
    const {storePayoutSummaryData, storePayoutSummaryDetails} = useContext(PaymentContext);
    const [hasMore, setHasMore] = useState(true);
    const [loadingPros, setLoadingPros] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadSorting, setLoadSorting] = useState(false);
    
  const [filterShow, setFilterShow] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

    const fetchMoreData = useCallback(async () => {
      if (!nextStephost || loadingPros) {
        setHasMore(false);
        return;
      }
      try {
        setLoadingPros(true);
        const nextPathname = new URL(nextStephost).search;
        await updatePayoutsWithPagination(nextPathname);
        if (nextStephost) {
          setHasMore(true);
        }
        setLoadingPros(false);
      } catch (error) {
        setLoadingPros(false);
        setHasMore(false);
        messageApi.open({
          type: "error",
          content: error?.message || GENERIC_ERROR_MESSAGE,
          duration: 5,
        });
      }
    }, [nextStephost, updatePayoutsWithPagination, messageApi]);
  

  const InitialDisplayFilters = {
    date: '',
    host: null,
    product: [],
    status: '',
    commission: '',
    store: []
  }
  const InitialStoreProductDateFilter = {
    store: [],
    product: [],
    date: ''
  }
  const [displayFilters, setDisplayFilter] = useState(InitialDisplayFilters);
  const [originalStoreProductDateFilter, setOriginalStoreProductDateFilter] = useState(InitialStoreProductDateFilter);
  const [storeProductDateFilter, setStoreProductDateFilter] = useState(InitialStoreProductDateFilter);
  const [activeFilter, setActiveFilter] = useState(''); // Default to 'Date'
  const [showFilter, setShowFilter] = useState(false);
  const [selectedDate, setSelectedDate] = useState("Last 30 Days");
  const [selectedProduct, setSelectedProduct] = useState("Bookcase Door");
  const [dateFilter, setDateFilter] = useState('');
  const [originalDateFilter, setOriginalDateFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState('');
  const [originalStartDateFilter, setOriginalStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [originalEndDateFilter, setOriginalEndDateFilter] = useState('');
  const [productFilter, setProductFilter] = useState('');
  const [originalProductFilter, setOriginalProductFilter] = useState('');
  const [clearFiltersLoading, setClearFiltersLoading] = useState(false);
  const [filteredDataLoading, setFilteredDataLoading] = useState(false);
  const [commissionFilter, setCommissionFilter] = useState('');
  const [storeFilter, setStoreFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [originalStatusFilter, setOriginalStatusFilter] = useState('');
  const [originalCommissionFilter, setOriginalCommissionFilter] = useState('');
  const [originalStoreFilter, setOriginalStoreFilter] = useState('');
  const [sorting, setSorting] = useState({
    demoDate: SORTING_STATUS.NONE,
    productName: SORTING_STATUS.NONE,
    duration: SORTING_STATUS.NONE,
    guestName: SORTING_STATUS.NONE,
    storeName: SORTING_STATUS.NONE,
  });
  const getPayouts = () => {
    if (loading) {
      console.log("loading", loading)
      return (
        <tr>
          <td colSpan={8}>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (updatedPayoutListWithPagination?.length < 1) {
        return (
          <tr>
            <td colSpan="11" className="text-center">
              No Payouts Found
            </td>
          </tr>
        );
      } else {
        return updatedPayoutListWithPagination?.map((updatedPayoutItem, index) => {
          return <PayoutAccepted key={index} hostDetails={updatedPayoutItem} filters={{commissionFilter:originalCommissionFilter, statusFilter: originalStatusFilter , startDate:originalStartDateFilter? originalStartDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT): null , endDate: originalEndDateFilter? originalEndDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT): null} }/>;
        });
      }
    }
  };

  useEffectOnce(() => {
    let third = storePayoutSummaryDetails(`?order=true`)
    const promise = Promise.all([third]);
    promise.then(() => {
      setLoading(false);
      setLoadingPros(false);
    });
  }, []);
  
// fetch initial data for the filters:
useEffectOnce(() => {
  let res = updatePayoutsWithPagination(`?order=true&startDate=&endDate=&paid=&commission=&store=&product=&failed=&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`);
  res
    .then(() => setLoading(false))
    .catch((err) => {
      setLoading(true);
    });

  fetchAllProducts('?')
    .then(() => {})
    .catch((err) => {
      console.log(err);
    });
    fetchAllStores('?')
    .then(() => {})
    .catch((err) => {
      console.log(err);
    });
}, [updatedPayoutListWithPagination]); //state to watch the data changes

    const getCurrentSorting = () => {
      const keys = Object.keys(sorting);
      for (let index = 0; index < keys.length; index++) {
        if (
          sorting[keys[index]] === SORTING_STATUS.ASCENDING ||
          sorting[keys[index]] === SORTING_STATUS.DESCENDING
        ) {
          return [keys[index], sorting[keys[index]]];
        }
      }
      return [null, null];
    };

    const handleFilterClick = (filterKey) => {
      setActiveFilter(filterKey); // Set the active tab based on filter clicked
      setFilterShow(true); // Show the modal
    };

    const applyFilters = () => {
      setFilteredDataLoading(true);
      let startD = '';
      let endD = '';
      if (startDateFilter) {
        startD = startDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
      }
      if (endDateFilter) {
        endD = endDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
      }
  
      const currentSorting = getCurrentSorting();
      let res;
      if(currentSorting? currentSorting[0] : false){
        res = updatePayouts(
          `?order=true&startDate=${startD}&endDate=${endD}&product=${productFilter.length > 0? '['+ productFilter + ']' : ''}&paid=${['Paid','Unpaid'].some(filter => statusFilter.includes(filter))? (statusFilter === 'Paid' ? true: false) : ''}&commission=${commissionFilter.toLowerCase()}&failed=${statusFilter === 'Failed'?  true : ""}&store=${storeFilter.length > 0 ? '['+ storeFilter + ']' : ""}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&${currentSorting[0]}=${currentSorting[1]}`
        );
      } else {
        res = updatePayouts(
          `?order=true&startDate=${startD}&endDate=${endD}&product=${productFilter.length > 0 ? '['+ productFilter + ']' : ''}&paid=${['Paid','Unpaid'].some(filter => statusFilter.includes(filter))? ( statusFilter === 'Paid' ? true: false) : ''}&commission=${commissionFilter.toLowerCase()}&store=${storeFilter.length > 0 ? '['+ storeFilter + ']' : ""}&failed=${statusFilter === 'Failed'?  true : ""}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
        );
      }
      storePayoutSummaryDetails(`?order=true&startDate=${startD}&endDate=${endD}&store=${storeFilter.length > 0 ? '['+ storeFilter + ']' : ""}&commission=${commissionFilter.toLowerCase()}&product=${productFilter.length > 0 ? '['+ productFilter + ']' : ''}&paid=${['Paid','Unpaid'].some(filter => statusFilter.includes(filter)) ? (statusFilter === 'Paid' ? true: false) : '' }&failed=${statusFilter === 'Failed'?  true : ""}`)
  
      res
        .then((response) => {
          console.log("Response", response?.data?.data?.next)
          setFilteredDataLoading(false);
          setOriginalDateFilter(dateFilter);
          setOriginalStartDateFilter(startDateFilter);
          setOriginalEndDateFilter(endDateFilter);
          setOriginalProductFilter(productFilter);
          setOriginalStatusFilter(statusFilter);
          setOriginalCommissionFilter(commissionFilter);
          setOriginalStoreFilter(storeFilter);
          setOriginalStoreProductDateFilter(storeProductDateFilter)
          setDisplayFilter({...storeProductDateFilter, status: statusFilter, commission: commissionFilter});
          setHasMore(response?.data?.data?.next ? true : false);
          handleFilterClose();
        })
        .catch((err) => {
          setFilteredDataLoading(true);
        });
    };
    const handleFilterClose = (setAllStates) => {
      if (setAllStates) {
        setDateFilter(originalDateFilter);
        setStartDateFilter(originalStartDateFilter);
        setEndDateFilter(originalEndDateFilter);
        setProductFilter(originalProductFilter);
        setStatusFilter(originalStatusFilter);
        setCommissionFilter(originalCommissionFilter);
        setStoreFilter(originalStoreFilter);
        setStoreProductDateFilter(originalStoreProductDateFilter);
        setDisplayFilter(()=>({...originalStoreProductDateFilter,status:originalStatusFilter,commission:originalCommissionFilter}));

      }
      setFilterShow(false);
    };
    const clearFilters = () => {
      setClearFiltersLoading(true);
      const res = updatePayouts(`?order=true&startDate=&endDate=&paid=&commission=&store=&product=&failed=`);
      storePayoutSummaryDetails(`?order=true`);
      res
        .then((response) => {
        setClearFiltersLoading(false);
        setOriginalDateFilter('');
        setOriginalStartDateFilter('');
        setOriginalEndDateFilter('');
        setOriginalStoreFilter('');
        setOriginalProductFilter('');
        setOriginalStatusFilter('');
        setOriginalCommissionFilter('');
        setDateFilter('');
        setDisplayFilter(InitialDisplayFilters);
        setOriginalStoreProductDateFilter(InitialStoreProductDateFilter);
        setStartDateFilter('');
        setEndDateFilter('');
        setStoreFilter([]);
        setProductFilter([]);
        setStatusFilter('');
        setCommissionFilter('');
        setStoreProductDateFilter(InitialStoreProductDateFilter);
        setFilterShow(false);
        setHasMore(response?.data?.data?.next ? true : false);
        })
        .catch((err) => {
          setClearFiltersLoading(true);
        });
    };
  if (loading || emailVerificationLoading) {
    return <PurebrandLoader />;
  } else {
    if (!userProfile?.complete_profile) {
      return <IncompleteProfile />;
    }
    if (!isEmailVerified) {
      return <></>
    }
  }

    const formatAmountToK = (amount) => {
      // Check if amount is a valid number
      if (isNaN(amount)) return 'Invalid Amount';
        
      // Handle small amounts below 1000
      if (amount < 1000) return amount.toString();
    
      // Divide by 1000 and add "K"
      return `${(amount / 1000).toFixed(1)}K`;
    };

      /**
       * This function is used to handle sorting on the table columns
       *
       * @return {void}
       */
    
      const handleSorting = (column) => {
        if (loadSorting) {
          return;
        }
        let startD = '';
        let endD = '';
        let sortingDirection = '';
        if (startDateFilter) {
          startD = startDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
        }
        if (endDateFilter) {
          endD = endDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
        }
    
        if (sorting[column] === SORTING_STATUS.ASCENDING) {
          sortingDirection = SORTING_STATUS.DESCENDING;
        } else if (sorting[column] === SORTING_STATUS.DESCENDING) {
          sortingDirection = SORTING_STATUS.NONE;
        } else {
          sortingDirection = SORTING_STATUS.ASCENDING;
        }
    
        const res = updatePayouts(
          // `?order=true&startDate=${startD}&endDate=${endD}&product=${productFilter.length > 0? '['+ productFilter + ']' : ''}&${column}=${sortingDirection}`
          `?order=true&startDate=${startD}&endDate=${endD}&product=${productFilter.length > 0? '['+ productFilter + ']' : ''}&paid=${['Paid','Unpaid'].some(filter => statusFilter.includes(filter))? (statusFilter === 'Paid' ? true: false) : ''}&commission=${commissionFilter.toLowerCase()}&failed=${statusFilter === 'Failed'?  true : ""}&store=${storeFilter.length > 0 ? '['+ storeFilter + ']' : ""}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&${column}=${sortingDirection}`
        );
        setLoadSorting(true);
        res
          .then((responsone) => {
            const newSorting = { ...sorting };
            Object.entries(newSorting).forEach(([key, value]) => {
              if (key === column && value === SORTING_STATUS.ASCENDING) {
                newSorting[key] = SORTING_STATUS.DESCENDING;
              } else if (
                key === column &&
                sorting[column] === SORTING_STATUS.DESCENDING
              ) {
                newSorting[key] = SORTING_STATUS.NONE;
              } else if (
                key === column &&
                sorting[column] === SORTING_STATUS.NONE
              ) {
                newSorting[key] = SORTING_STATUS.ASCENDING;
              } else {
                newSorting[key] = SORTING_STATUS.NONE;
              }
            });
            console.log("Setting sorting", responsone)
            setLoadSorting(false);
            setHasMore(true);
            setSorting(newSorting);
          })
          .catch((err) => {
            setLoadSorting(false);
            console.log(err);
          });
      };
  
    const getTooltipTitle = (columnName) => {
      if (sorting[columnName] === SORTING_STATUS.ASCENDING) {
        return "Click here to sort descending";
      } else if (sorting[columnName] === SORTING_STATUS.DESCENDING) {
        return "Click here to clear sorting";
      } else {
        return "Click here to sort ascending";
      }
    };

    return (
      <>
        {contextHolder}
        <div className="payout-page-title-wrapper">
          <p>My Payouts</p>
        </div>
        <p className="payout-page-note-section">
          <h6>
            <strong>Note:</strong>
          </h6>
          <strong>Payout Events:</strong> Payments are processed every two weeks
          on Fridays around midnight (UTC-6). You should receive the funds
          within a few business days after that. If you need to change where
          payouts are deposited, please do so in your{' '}
          <a
            target="_blank" 
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_DOTS_BASE_URL}`}>
            Dots Account
          </a>
          .<br />
          <strong>Demo Commissions:</strong> Payment for demos held between
          Payout Events.
          <br />
          <strong>Sales Commissions:</strong> Payment for sales fulfilled by the
          Store between Payout Events. Note: A sales commission may be
          attributed to a demo from a previous payment cycle.
        </p>
        <div className="payout-data-section">
          <PayoutFilterbar
            onFilterClick={handleFilterClick}
            displayFilters={displayFilters}
          />
          {/* Render the FilterComponent */}

          {/* Summary Section */}
          <div className="summary-section">
            <div className="summary-card">
              <h5>Total Meetings</h5>
              <h2>
                {storePayoutSummaryData
                  ? storePayoutSummaryData.total_successfully_ended_meetings
                  : 0}
              </h2>
              <p>{displayFilters.date ? displayFilters.date : 'All Time'}</p>
            </div>
            <div className="summary-card">
              <h5>Revenue Generated</h5>
              <h2>
                $
                {formatAmountToK(
                  storePayoutSummaryData
                    ? storePayoutSummaryData.total_revenue_generated
                    : 0
                )}
              </h2>
              <p>Conversion Rate</p>
              <h3>
                {storePayoutSummaryData
                  ? storePayoutSummaryData.conversion_ratio
                  : 0}
                %
              </h3>
              <p>
                {displayFilters.date ? displayFilters.date : 'All Time'}
              </p>
            </div>
            <div className="summary-card">
              <h5>Total Payouts</h5>
              <h2>
                $
                {formatAmountToK(
                  storePayoutSummaryData
                    ? originalCommissionFilter
                      ? originalCommissionFilter === "Demo"
                        ? storePayoutSummaryData.demo_earnings
                        : originalCommissionFilter === "Sales"
                        ? storePayoutSummaryData.sales_earnings
                        : null
                      : storePayoutSummaryData.total_payouts_generated
                    : 0
                )}
              </h2>
              <p>{displayFilters.date ? displayFilters.date : "All Time"}</p>
            </div>
          </div>

          {/* Table Section */}
          <div
            className='table-container'
            id='scrollableDiv'
            style={{ height: "600px", overflow: "auto" }}
          >
            <InfiniteScroll
              dataLength={updatedPayoutListWithPagination.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                (loading||loadingPros|| loadSorting) &&<div className='d-flex justify-content-center'>
                  <Spinner animation='grow' />
                  <Spinner animation='grow' />
                  <Spinner animation='grow' />
                </div>
                
              }
          scrollableTarget="scrollableDiv"
              endMessage={
            <div className="text-center py-3 text-muted">
                  {!hasMore && "No more data to load."}
                </div>
              }
            >
          <div className="table-wrapper">
            <Table responsive className="table-payout">
                  <thead>
                    <tr>
                      <th>
                        <div className='arrows'>
                          Store{" "}
                          <Tooltip title={getTooltipTitle("storeName")}>
                            <div
                              className='arrow-grid'
                              onClick={() => handleSorting("storeName")}
                            >
                              <CaretUp />
                              <CaretDown />
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                      <th>
                        <div className='arrows'>
                          Product{" "}
                          <Tooltip title={getTooltipTitle("productName")}>
                            <div
                              className='arrow-grid'
                              onClick={() => {
                                handleSorting("productName");
                              }}
                            >
                              <CaretUp />
                              <CaretDown />
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                      <th>
                        <div className='arrows'>
                          Payout{" "}
                          <Tooltip>
                            <div className='arrow-grid'>
                              <CaretUp className='no-sort' />
                              <CaretDown className='no-sort' />
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                      <th>
                        <div className='arrows'>
                          Status
                          <Tooltip>
                            <div className='arrow-grid'>
                              <CaretUp className='no-sort' />{" "}
                              <CaretDown className='no-sort' />
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                      <th>
                        <div className='arrows'>
                          Commission
                          <Tooltip>
                            <div className='arrow-grid'>
                              <CaretUp className='no-sort' />{" "}
                              <CaretDown className='no-sort' />
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                      <th>
                        <div className='arrows'>
                          Result{" "}
                          <Tooltip >
                            <div
                              className='arrow-grid'
                            >
                              <CaretUp className="no-sort" />
                              <CaretDown className="no-sort"/>
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                      <th>
                        <div className='arrows'>
                          Duration{" "}
                          <Tooltip title={getTooltipTitle("duration")}>
                            <div
                              className='arrow-grid'
                              onClick={() => {
                                handleSorting("duration");
                              }}
                            >
                              <CaretUp />
                              <CaretDown />
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                      <th>
                        <div className='arrows'>
                          Guest{" "}
                          <Tooltip title={getTooltipTitle("guestName")}>
                            <div
                              className='arrow-grid'
                              onClick={() => {
                                handleSorting("guestName");
                              }}
                            >
                              <CaretUp />
                              <CaretDown />
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                      <th>
                        <div className='arrows'>
                          Earned Date{" "}
                          <Tooltip >
                            <div
                              className='arrow-grid'
                            >
                              <CaretUp className="no-sort"/>
                              <CaretDown className="no-sort"/>
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                      <th>
                        <div className='arrows'>
                          Demo Date
                          <Tooltip title={getTooltipTitle("demoDate")}>
                            <div
                              className='arrow-grid'
                              onClick={() => handleSorting("demoDate")}
                            >
                              <CaretUp /> <CaretDown />
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                      <th>
                        <div className='arrows'>
                          Paid Date
                          <Tooltip>
                            <div className='arrow-grid'>
                              <CaretUp className='no-sort' />{" "}
                              <CaretDown className='no-sort' />
                            </div>
                          </Tooltip>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      getPayouts()
    }
                  </tbody>
                </Table>
              </div>
            </InfiniteScroll>
          </div>

          <PayoutFilterModal
            showStatus={filterShow}
            clickStatus={handleFilterClose}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            setDisplayFilter={setDisplayFilter}
            setStartDateFilter={setStartDateFilter}
            setEndDateFilter={setEndDateFilter}
            setProductFilter={setProductFilter}
            setStatusFilter={setStatusFilter}
            setCommissionFilter={setCommissionFilter}
            originalStatusFilter={originalStatusFilter}
            originalCommissionFilter={originalCommissionFilter}
            originalStoreFilter={originalStoreFilter}
            storeFilter={storeFilter}
            setStoreFilter={setStoreFilter}
            startDateFilter={startDateFilter}
            endDateFilter={endDateFilter}
            productFilter={productFilter}
            statusFilter={statusFilter}
            commissionFilter={commissionFilter}
            applyFilters={applyFilters}
            originalDateFilter={originalDateFilter}
            originalStartDateFilter={originalStartDateFilter}
            originalEndDateFilter={originalEndDateFilter}
            originalProductFilter={originalProductFilter}
            clearFilters={clearFilters}
            loading={filteredDataLoading}
            clearFiltersLoading={clearFiltersLoading}
            activeFilter={activeFilter} // Current active filter tab
            setActiveFilter={setActiveFilter} // Function to set active filter tab
            displayFilter={displayFilters}
            setOriginalStoreProductDateFilter={
              setOriginalStoreProductDateFilter
            }
            originalStoreProductDateFilter={originalStoreProductDateFilter}
            setStoreProductDateFilter={setStoreProductDateFilter}
            storeProductDateFilter={storeProductDateFilter}
          />
        </div>
      </>
    );
  
};

export default Payouts;

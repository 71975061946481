import React, { useCallback, useContext, useState } from "react";
import { Row, Col, Table, Form, Spinner } from "react-bootstrap";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import PaymentContext from "context/PaymentContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import PaymentListUser from "Pages/dashboard/PureBrand/Payments/PaymentListUser";
import { Tabs } from "react-bootstrap";
import { Button, message } from "antd";
import { downloadAccountingData } from "Services/storeService";
import "./paymentStoreList.css";
import moment from "moment";
import { GENERIC_ERROR_MESSAGE } from "utils/constants";
import FailedListUser from "Pages/dashboard/PureBrand/Payments/FailedListUser";
import { Doughnut } from "react-chartjs-2";
import { CaretDown, CaretUp } from 'react-bootstrap-icons';
import { TEST_IDS } from './testIds';
import PaymentsFilterModal from "components/Modal/PaymentsFilterModal"; // Replace with the new FilterModal component
import { DATE_FORMATS, SORTING_STATUS } from 'utils/constants';
import DemoContext from "context/DemoContext";
import PaymentStoreFilterbar from "components/Filters/PaymentStoreFilterBar/PaymentStoreFilterbar";
import RechartsTest from "components/Chart/Recharts";
import InfiniteScroll from "react-infinite-scroll-component";
import { Tooltip } from "antd";

const PaymentStoreList = () => {
  const {
    payToHostListWithPagination,
    payToHostlist,
    nextStep,
    storePayoutSummaryDetails,
    storePayoutSummaryData,
    adminPaymentListWithPagination,
  } = useContext(PaymentContext);

  const [messageApi, contextHolder] = message.useMessage();

  const { fetchAllHosts, fetchAllProducts } = useContext(DemoContext);
  const [hasMore, setHasMore] = useState(true);
  const [loadingPros, setLoadingPros] = useState(true);
  const [loadSorting, setLoadSorting] = useState(false);
  const fetchMoreData = useCallback(async () => {
    if (!nextStep || loadingPros) {
      setHasMore(false);
      return;
    }
    try {
      setLoadingPros(true);
      const nextPathname = new URL(nextStep).search;
      await payToHostListWithPagination(nextPathname);
      if (nextStep) {
        setHasMore(true);
      }
      setLoadingPros(false);
    } catch (error) {
      setLoadingPros(false);
      setHasMore(false);
      messageApi.open({
        type: "error",
        content: error?.message || GENERIC_ERROR_MESSAGE,
        duration: 5,
      });
    }
  }, [nextStep, adminPaymentListWithPagination, messageApi]);

  const InitialDisplayFilters = {
    date: '',
    host: [],
    product: [],
    status: '',
    commission: '',
    store: null
  }
  const InitialHostProductDateFilter = {
    product: [],
    host: [],
    date:'',
  }
  const [loading, setLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [activeFilter, setActiveFilter] = useState(''); // Default to 'Date'
  const [dateFilter, setDateFilter] = useState('');
  const [displayFilters, setDisplayFilter] = useState(InitialDisplayFilters);
  const [originalHostProductDateFilter, setOriginalHostProductDateFilters] = useState(InitialHostProductDateFilter);
  const [hostProductDateFilter, setHostProductDateFilter] = useState(InitialHostProductDateFilter);
  const [originalDateFilter, setOriginalDateFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState('');
  const [originalStartDateFilter, setOriginalStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');
  const [originalEndDateFilter, setOriginalEndDateFilter] = useState('');
  const [hostFilter, setHostFilter] = useState('');
  const [originalHostFilter, setOriginalHostFilter] = useState('');
  const [productFilter, setProductFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [commissionFilter, setCommissionFilter] = useState('');
  const [originalProductFilter, setOriginalProductFilter] = useState('');
  const [originalStatusFilter, setOriginalStatusFilter] = useState('');
  const [originalCommissionFilter, setOriginalCommissionFilter] = useState('');
  const [sorting, setSorting] = useState({
    demoDate: SORTING_STATUS.NONE,
    productName: SORTING_STATUS.NONE,
    duration: SORTING_STATUS.NONE,
    guestName: SORTING_STATUS.NONE,
    hostName: SORTING_STATUS.NONE,
  });
  const [clearFiltersLoading, setClearFiltersLoading] = useState(false);
  const [filteredDataLoading, setFilteredDataLoading] = useState(false);

  const getCurrentSorting = () => {
    const keys = Object.keys(sorting);
    for (let index = 0; index < keys.length; index++) {
      if (
        sorting[keys[index]] === SORTING_STATUS.ASCENDING ||
        sorting[keys[index]] === SORTING_STATUS.DESCENDING
      ) {
        return [keys[index], sorting[keys[index]]];
      }
    }
  };
  /**
   * This function is used to get the text of the download button
   *
   * @param {boolean} paid determines whether it is for paid revenues tab or not
   * @param {boolean} failed determines whether it is for failed revenues tab or not
   * @return {string} Text to be displayed on the download button
   */

  const getDownloadButtonText = (paid, failed) => {
    if (paid) {
      return "Download Paid Revenues Data";
    } else if (failed) {
      return "Download Failed Revenues Data";
    } else {
      return "Download Unpaid Revenues Data";
    }
    return [null, null];
  };
  const applyFilters = () => {
    setFilteredDataLoading(true);
    let startD = '';
    let endD = '';
    if (startDateFilter) {
      startD = startDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
    }
    if (endDateFilter) {
      endD = endDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
    }

    const currentSorting = getCurrentSorting();
    let res;
    if(currentSorting? currentSorting[0] : false){
      res = payToHostlist(
        `?order=true&startDate=${startD}&endDate=${endD}&host=${
          hostFilter.length > 0 ? "[" + hostFilter + "]" : ""
        }&product=${
          productFilter.length > 0 ? "[" + productFilter + "]" : ""
        }&paid=${
          ["Paid", "Unpaid"].some((filter) => statusFilter.includes(filter))
            ? statusFilter === "Paid"
              ? true
              : false
            : ""
        }&commission=${commissionFilter.toLowerCase()}&failed=${
          statusFilter === "Failed" ? true : ""
        }&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&${currentSorting[0]}=${currentSorting[1]}`
      );
    }else{ 
    res = payToHostlist(
      `?order=true&startDate=${startD}&endDate=${endD}&host=${
        hostFilter.length > 0 ? "[" + hostFilter + "]" : ""
      }&product=${
        productFilter.length > 0 ? "[" + productFilter + "]" : ""
      }&paid=${
        ["Paid", "Unpaid"].some((filter) => statusFilter.includes(filter))
          ? statusFilter === "Paid"
            ? true
            : false
          : ""
      }&commission=${commissionFilter.toLowerCase()}&failed=${
        statusFilter === "Failed" ? true : ""
      }&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    );}
    storePayoutSummaryDetails(
      `?order=true&startDate=${startD}&endDate=${endD}&host=${
        hostFilter.length > 0 ? "[" + hostFilter + "]" : ""
      }&commission=${commissionFilter.toLowerCase()}&product=${
        productFilter.length > 0 ? "[" + productFilter + "]" : ""
      }&failed=${statusFilter === "Failed" ? true : ""}&paid=${
        ["Paid", "Unpaid"].some((filter) => statusFilter.includes(filter))
          ? statusFilter === "Paid"
            ? true
            : false
          : ""
      }`
    );
  
    res
      .then((response) => {
        setFilteredDataLoading(false);
        setOriginalDateFilter(dateFilter);
        setOriginalStartDateFilter(startDateFilter);
        setOriginalEndDateFilter(endDateFilter);
        setOriginalHostFilter(hostFilter);
        setOriginalProductFilter(productFilter);
        setOriginalStatusFilter(statusFilter);
        setOriginalCommissionFilter(commissionFilter);
        setOriginalHostProductDateFilters(hostProductDateFilter);
        setDisplayFilter({
          ...hostProductDateFilter,
          status: statusFilter,
          commission: commissionFilter,
        });
        setHasMore(response.data.data.next? true: false);
        handleFilterClose();
      })
      .catch((err) => {
        setFilteredDataLoading(true);
      });
  };
  useEffectOnce(() => {
    let res = payToHostListWithPagination(
      `?order=true&startDate=&endDate=&paid=&commission=&host=&product=&failed=&timezone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`
    );
    res
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(true);
      });

    fetchAllHosts('?')
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });

    fetchAllProducts('?')
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  }, [adminPaymentListWithPagination]);

  const clearFilters = () => {
    setClearFiltersLoading(true);
    const res = payToHostlist(
      `?order=true&startDate=&endDate=&paid=&commission=&host=&product=&failed=`
    );
    storePayoutSummaryDetails(`?order=true`);
    res
      .then((response) => {
        setClearFiltersLoading(false);
        setOriginalDateFilter('');
        setOriginalStartDateFilter('');
        setOriginalEndDateFilter('');
        setOriginalHostFilter('');
        setOriginalProductFilter('');
        setOriginalStatusFilter('');
        setOriginalCommissionFilter('');
        setDateFilter('');
        setDisplayFilter(InitialDisplayFilters);
        setOriginalHostProductDateFilters(InitialHostProductDateFilter);
        setStartDateFilter('');
        setEndDateFilter('');
        setHostFilter([]);
        setProductFilter([]);
        setStatusFilter('');
        setCommissionFilter('');
        setHostProductDateFilter(InitialHostProductDateFilter);
        setHasMore(response.data.data.next? true: false);
        setFilterShow(false); // Close modal after clearing filters
      })
      .catch(() => {
        setClearFiltersLoading(false);
      });
  };

  useEffectOnce(() => {
    let third = storePayoutSummaryDetails(`?order=true`);
    const promise = Promise.all([third]);
    promise.then(() => {
      setLoading(false);
      setLoadingPros(false);
    });
  }, []);

  if (loading === true) {
    return <PurebrandLoader />;
  }

  const handleFilterClose = (setAllStates) => {
    if (setAllStates) {
      // Apply the filters
      setDateFilter(originalDateFilter);
      setStartDateFilter(originalStartDateFilter);
      setEndDateFilter(originalEndDateFilter);
      setHostFilter(originalHostFilter);
      setProductFilter(originalProductFilter);
      setStatusFilter(originalStatusFilter);
      setHostProductDateFilter(originalHostProductDateFilter);
      setCommissionFilter(originalCommissionFilter);
      setDisplayFilter({
        ...originalHostProductDateFilter,
        status: originalStatusFilter,
        commission: originalCommissionFilter,
      });
    }

    setFilterShow(false);
  };

  const downloadData = async (status) => {
    try {
      let data;

      let startD = '';
      let endD = '';
      if (startDateFilter) {
        startD = startDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
      }
      if (endDateFilter) {
        endD = endDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
      }
      const fileName = `${status} Revenues`;
      const queryParams = `?order=true&startDate=${startD || ""}&endDate=${
        endD || ""
      }&host=${
        hostFilter.length > 0 ? "[" + hostFilter.join(",") + "]" : [""]
      }&product=${
        productFilter.length > 0 ? "[" + productFilter.join(",") + "]" : [""]
      }&paid=${
        status === "Paid" ? "true" : status === "Unpaid" ? "false" : ""
      }&commission=${commissionFilter.toLowerCase() || ""}&failed=${
        status === "Failed" ? "true" : ""
      }&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

      setDownloadLoading(true);
      const res = await downloadAccountingData(queryParams);
      const blob = new Blob([res.data], { type: "text/csv" });
      const downloadURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadURL;
      a.style.display = "none";
      a.download = `${fileName}-${moment().format("MM-DD-YYYY")}.csv`;
      a.target = "_blank";
      document.body.append(a);
      a.click();
      URL.revokeObjectURL(downloadURL);
      document.body.removeChild(a);

      setDownloadLoading(false);
    } catch (err) {
      setDownloadLoading(false);
      messageApi.open({
        type: "error",
        content:
          err?.response?.data?.message?.phone_number ||
          err?.message ||
          GENERIC_ERROR_MESSAGE,
        duration: 5,
      });
    }
  };

  const getlist = () => {
    if (adminPaymentListWithPagination?.length < 1) {
      return (
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
            <td className="no-data-wrap">No data available yet!</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    } else {
      return adminPaymentListWithPagination?.map((item, index) => (
        <PaymentListUser
          key={index}
          hostDetails={item}
          filters={{
            commissionFitler: originalCommissionFilter,
            statusFilter: originalStatusFilter,
            startDate: originalStartDateFilter
              ? originalStartDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT)
              : null,
            endDate: originalEndDateFilter
              ? originalEndDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT)
              : null,
          }}
        />
      ));
    }
  };

  const handleSorting = (column) => {
    if (loadSorting) {
      return;
    }
    let startD = '';
    let endD = '';
    let sortingDirection = '';
    if (startDateFilter) {
      startD = startDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
    }
    if (endDateFilter) {
      endD = endDateFilter.format(DATE_FORMATS.GENERIC_DATE_FORMAT);
    }

    if (sorting[column] === SORTING_STATUS.ASCENDING) {
      sortingDirection = SORTING_STATUS.DESCENDING;
    } else if (sorting[column] === SORTING_STATUS.DESCENDING) {
      sortingDirection = SORTING_STATUS.NONE;
    } else {
      sortingDirection = SORTING_STATUS.ASCENDING;
    }

    const res = payToHostlist(
      `?order=true&startDate=${startD}&endDate=${endD}&host=${
        hostFilter.length > 0 ? "[" + hostFilter + "]" : ""
      }&product=${
        productFilter.length > 0 ? "[" + productFilter + "]" : ""
      }&paid=${
        ["Paid", "Unpaid"].some((filter) => statusFilter.includes(filter))
          ? statusFilter === "Paid"
            ? true
            : false
          : ""
      }&commission=${commissionFilter.toLowerCase()}&failed=${
        statusFilter === "Failed" ? true : ""
      }&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}&${column}=${sortingDirection}`
    );
    setLoadSorting(true);
    res
      .then(() => {
        const newSorting = { ...sorting };
        Object.entries(newSorting).forEach(([key, value]) => {
          if (key === column && value === SORTING_STATUS.ASCENDING) {
            newSorting[key] = SORTING_STATUS.DESCENDING;
          } else if (
            key === column &&
            sorting[column] === SORTING_STATUS.DESCENDING
          ) {
            newSorting[key] = SORTING_STATUS.NONE;
          } else if (
            key === column &&
            sorting[column] === SORTING_STATUS.NONE
          ) {
            newSorting[key] = SORTING_STATUS.ASCENDING;
          } else {
            newSorting[key] = SORTING_STATUS.NONE;
          }
        });
        setLoadSorting(false);
        setHasMore(true);
        setSorting(newSorting);
      })
      .catch((err) => {
        setLoadSorting(false);
        console.log(err);
      });
  };

  const getTooltipTitle = (columnName) => {
    if (sorting[columnName] === SORTING_STATUS.ASCENDING) {
      return "Click here to sort descending";
    } else if (sorting[columnName] === SORTING_STATUS.DESCENDING) {
      return "Click here to clear sorting";
    } else {
      return "Click here to sort ascending";
    }
  };

  const handleFilterClick = (filterKey) => {
    setActiveFilter(filterKey); // Set the active tab based on filter clicked
    setFilterShow(true); // Show the modal
  };

  const formatAmountToK = (amount) => {
    // Check if amount is a valid number
    if (isNaN(amount)) return "Invalid Amount";

    // Handle small amounts below 1000
    if (amount < 1000) return amount.toString();

    // Divide by 1000 and add "K"
    return `${(amount / 1000).toFixed(1)}K`;
  };

  const DataForChart = [
    {
      name: "Success",
      value:
        storePayoutSummaryData?.total_successfully_ended_meetings -
          (storePayoutSummaryData?.total_guest_no_show_meetings +
            storePayoutSummaryData?.total_under_time_meetings) || 0,
    },
    {
      name: "No Shows",
      value:
        storePayoutSummaryData?.total_guest_no_show_meetings +
          storePayoutSummaryData?.total_under_time_meetings || 0,
    },
  ];

  return (
    <div>
      {contextHolder}
      <PaymentStoreFilterbar
        onFilterClick={handleFilterClick}
        displayFilters={displayFilters}
      />
      {/* Summary Section */}
      <div className="summary-section">
        <div className="summary-card">
          <h5>Total Meetings</h5>
          <h2>
            {storePayoutSummaryData
              ? storePayoutSummaryData.total_successfully_ended_meetings
              : 0}
          </h2>
          <p>{displayFilters.date ? displayFilters.date : 'All Time'}</p>
        </div>
        <div className="summary-card">
          <h5>Revenue Generated</h5>
          <h2>
            $
            {formatAmountToK(
              storePayoutSummaryData
                ? storePayoutSummaryData.total_revenue_generated
                : 0
            )}
          </h2>
          <p>Conversion Rate</p>
          <h3>
            {storePayoutSummaryData
              ? storePayoutSummaryData.conversion_ratio
              : 0}
            %
          </h3>
          <p>
            {displayFilters.date ? displayFilters.date : 'All Time'}
          </p>
        </div>
        <div className="summary-card">
          <h5>Total Payouts</h5>
          <h2>
            $
            {formatAmountToK(
              storePayoutSummaryData
                ? originalCommissionFilter
                  ? originalCommissionFilter === "Demo"
                    ? storePayoutSummaryData.demo_earnings
                    : originalCommissionFilter === "Sales"
                    ? storePayoutSummaryData.sales_earnings
                    : null
                  : storePayoutSummaryData.total_payouts_generated
                : 0
            )}
          </h2>
          <p>{displayFilters.date ? displayFilters.date : 'All Time'}</p>
        </div>
        <div className="summary-card">
          <h5>Meeting Results</h5>
          <div className="chart-container">
            {/* {getDonutChart(
              (storePayoutSummaryData?.total_guest_no_show_meetings+storePayoutSummaryData?.total_unsuccessfull_meetings + storePayoutSummaryData?.total_under_time_meetings)  || 0,
              (storePayoutSummaryData?.total_successfully_ended_meetings - (storePayoutSummaryData?.total_guest_no_show_meetings+storePayoutSummaryData?.total_unsuccessfull_meetings + storePayoutSummaryData?.total_under_time_meetings)) || 0,
              '',
              ['Failure', 'Success'], // Pass legend labels dynamically
              'Meeting Results' // Static chart title
            )} */}
            {<RechartsTest data={DataForChart} /> || "No Data for the chart"}
          </div>
          <p>{displayFilters.date ? displayFilters.date : 'All Time'}</p>
        </div>
      </div>
      {/* Table Section */}
      <div className="table-container" id="scrollableDiv" style={{ height: '600px', overflow: 'auto' }}>
        <InfiniteScroll
          dataLength={adminPaymentListWithPagination.length}
          next={fetchMoreData}
          hasMore={hasMore}
          // loader={<PurebrandLoader />}

          // scrollThreshold={0.8} // Will start loading more when 80% scrolled down
          loader={
            (loading|| loadingPros || loadSorting) && <div className='d-flex justify-content-center 456'>
                <Spinner animation='grow' />
                <Spinner animation='grow' />
                <Spinner animation='grow' />
              </div>
          }
          scrollableTarget="scrollableDiv"
          endMessage={
            <div className='text-center py-3 text-muted'>
              {!hasMore && "No more data to load."}
            </div>
          }
        >
          <div className="table-wrapper">
            <Table responsive className="table-payout">
              <thead>
                <tr>
                  <th><div className="arrows">Host{' '}
                      <Tooltip title={getTooltipTitle("hostName")}>
                        <div
                          className='arrow-grid'
                          onClick={() => {
                            handleSorting("hostName");
                          }}
                        >
                          <CaretUp />
                          <CaretDown />
                        </div>
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className='arrows'>
                      Product{" "}
                      <Tooltip title={getTooltipTitle("productName")}>
                        <div
                          className='arrow-grid'
                          onClick={() => {
                            handleSorting("productName");
                          }}
                        >
                          <CaretUp />
                          <CaretDown />
                        </div>
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className='arrows'>
                      Payout{" "}
                      <Tooltip >
                        <div className='arrow-grid'>
                          <CaretUp className='no-sort' />
                          <CaretDown className='no-sort' />
                        </div>
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className='arrows'>
                      Status
                      <Tooltip>
                        <div className='arrow-grid'>
                          <CaretUp className='no-sort' />{" "}
                          <CaretDown className='no-sort' />
                        </div>
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className='arrows'>
                      Commission
                      <Tooltip>
                        <div className='arrow-grid'>
                          <CaretUp className='no-sort' />{" "}
                          <CaretDown className='no-sort' />
                        </div>
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className='arrows '>
                      Result{" "}
                      <Tooltip >
                        <div className='arrow-grid'>
                          <CaretUp className='no-sort' />
                          <CaretDown className='no-sort' />
                        </div>
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className='arrows'>
                      Duration{" "}
                      <Tooltip title={getTooltipTitle("duration")}>
                        <div
                          className='arrow-grid'
                          onClick={() => {
                            handleSorting("duration");
                          }}
                        >
                          <CaretUp />
                          <CaretDown />
                        </div>
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className='arrows'>
                      Guest{" "}
                      <Tooltip title={getTooltipTitle("guestName")}>
                        <div
                          className='arrow-grid'
                          onClick={() => {
                            handleSorting("guestName");
                          }}
                        >
                          <CaretUp />
                          <CaretDown />
                        </div>
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className='arrows'>
                      Earned Date{" "}
                      <Tooltip >
                        <div className='arrow-grid'>
                          <CaretUp className='no-sort' />
                          <CaretDown className='no-sort' />
                        </div>
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className='arrows'>
                      Demo Date
                      <Tooltip title={getTooltipTitle("demoDate")}>
                        <div
                          className='arrow-grid'
                          onClick={() => handleSorting("demoDate")}
                        >
                          <CaretUp /> <CaretDown />
                        </div>
                      </Tooltip>
                    </div>
                  </th>
                  <th>
                    <div className='arrows'>
                      Paid Date
                      <Tooltip>
                        <div className='arrow-grid'>
                          <CaretUp className='no-sort' />{" "}
                          <CaretDown className='no-sort' />
                        </div>
                      </Tooltip>
                    </div>
                  </th>{" "}
                </tr>
              </thead>
              <tbody>
                {loadSorting ? (
                  <div className='d-flex justify-content-center'>
                    <Spinner animation='grow' />
                    <Spinner animation='grow' />
                    <Spinner animation='grow' />
                  </div>
                ) : (
                  getlist()
                )}
              </tbody>
            </Table>
          </div>
        </InfiniteScroll>
      </div>

      {/* Download Data Button */}
      <div className="download-data-button">
        <Button
          onClick={() => {
            downloadData(statusFilter);
          }}
          loading={downloadLoading}
          className="download-data-btn">
          Download Data
        </Button>
      </div>

      <PaymentsFilterModal
        showStatus={filterShow}
        clickStatus={handleFilterClose}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
        setDisplayFilter={setDisplayFilter}
        setStartDateFilter={setStartDateFilter}
        setEndDateFilter={setEndDateFilter}
        setHostFilter={setHostFilter}
        setProductFilter={setProductFilter}
        setStatusFilter={setStatusFilter}
        setCommissionFilter={setCommissionFilter}
        originalStatusFilter={originalStatusFilter}
        originalCommissionFilter={originalCommissionFilter}
        startDateFilter={startDateFilter}
        endDateFilter={endDateFilter}
        hostFilter={hostFilter}
        productFilter={productFilter}
        statusFilter={statusFilter}
        commissionFilter={commissionFilter}
        applyFilters={applyFilters}
        originalDateFilter={originalDateFilter}
        originalStartDateFilter={originalStartDateFilter}
        originalEndDateFilter={originalEndDateFilter}
        originalHostFilter={originalHostFilter}
        originalProductFilter={originalProductFilter}
        clearFilters={clearFilters}
        loading={filteredDataLoading}
        clearFiltersLoading={clearFiltersLoading}
        activeFilter={activeFilter} // Current active filter tab
        setActiveFilter={setActiveFilter} // Function to set active filter tab
        displayFilter={displayFilters}
        setOriginalHostProductDateFilters={setOriginalHostProductDateFilters}
        originalHostProductDateFilters={originalHostProductDateFilter}
        hostProductDateFilter={hostProductDateFilter}
        setHostProductDateFilter={setHostProductDateFilter}
      />
    </div>
  );
};

export default PaymentStoreList;
